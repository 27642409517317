
import {
  defineComponent,
  onActivated,
  ref,
  watch,
  nextTick,
  computed,
} from "vue";
import banner from "@/components/common/Swiper.vue";
import list from "@/components/common/list.vue";
import bnccProductCard from "@/components/common/bnccProductCard.vue";
import { useRoute, useRouter, onBeforeRouteLeave } from "vue-router";
//  utils
import api from "@/api/axios";
//ts
import { productMap, strainOrCellsMap } from "@/hooks/useProduct";

export default defineComponent({
  name: "product",
  components: {
    banner,
    list,
    bnccProductCard,
  },
  setup() {
    const list = ref(null);
    const productList = ref(null);
    const route = useRoute();
    const anclassId = ref(route.query.anclassId|| "1");
    const nclassId = ref(route.query.nclassId || "1");
    const router = useRouter();
    const producturl = computed(() => {
      return `/M/Product/getBnccListByClassify?anclassId=${anclassId.value}&nclassId=${nclassId.value}`;
    });
    const refreshList = ref(true);
    // const productparams = {
    //   anclassID: 1,
    // };
    api.get("/M/Product").then((res): void => {
      list.value = res.data.obj.listimg;
    });
    watch(
      [
        () => {
          return route.query;
        },
        () => {
          return route.name;
        },
      ],
      (to, from) => {

          // 从分类跳转 或者是从首页的 标准物质跳转 会从新请求数据渲染 ， 
          // 侧边栏的链接跳转直接复用之前的数据
        if (from[1] == "BnccCategory") {
          refreshList.value = false;
          anclassId.value = String(route.query.anclassId);
          nclassId.value = String(route.query.nclassId);
          nextTick(() => {
            refreshList.value = true;
          });
        } else if (from[1] == "Index") {
          refreshList.value = false;
          anclassId.value = "0";
          nclassId.value = "0";
          nextTick(() => {
            refreshList.value = true;
          });
        }
      }
    );

    //  切换列表菜单

    return {
      list,
      productList,
      producturl,
      productMap,
      strainOrCellsMap,
      // productparams,
      refreshList,
    };
  },
});
